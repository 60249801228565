import Sucursal from "#interfaces/Sucursal";
import { QuotationTypeCatalog } from "#interfaces/Quotation";

// import moment from "moment";

export const PAYPAL_TOKEN = "token";
export const PAYPAL_FAIL_FLAG = "fail";
export const TOP_PARTS_IDS = [2, 42, 49, 9, 11, 10, 25, 27, 18, 57, 58, 56];
export const DEFAULT_GENERIC_PRODUCT_ID = 1111;
export const DIAGNOSTIC_SERVICE_ID = 56;
export const QUOTATION_AVAILABLE_TYPES = [
  QuotationTypeCatalog.HOME_SERVICE,
  QuotationTypeCatalog.LOCAL_SERVICE,
];

/** Root Object that contains all routes constants */
export const ROUTES = {
  /** Route Path for Home or Landing Page */
  ROOT: "/",
  QUOTE: "/cotizar/reparacion",
  MYREPAIR: "/mireparacion",
  SHOPS: "/sucursales",
  PAYMENT: "/cotizar/pago",
  TERMS: "/terminos",
};

export const GAEVENT_CATS = {
  ENGAGEMENT: "engagement",
  ECOMMERCE: "ecommerce",
  COTIZADOR_SELECCION: "cotizador_seleccion",
};

export const SYNTH_SERVICES = {
  DIAGNOSTIC: {
    idPart: 56,
    name: "DIAGNOSTICO / SERVICIO",
    abbreviation: "dgsv",
    slug: "valoracion-diagnostico",
  },
  WET_EQUIPMENT: {
    idPart: 57,
    name: "MI EQUIPO ESTA MOJADO",
    abbreviation: "wteq",
    slug: "mi-equipo-esta-mojado",
  },
  NOT_TURNINGON: {
    idPart: 58,
    name: "MI EQUIPO NO ENCIENDE",
    abbreviation: "eqntturn",
    slug: "mi-equipo-no-enciende",
  },
};

export const DEFAULT_OFFICE = {
  idOffice: 12,
  name: "Condesa",
  homeservice: 1,
  fieldservice: 1,
  service: 1,
  address:
    "Av. Nuevo Leon 217A int. F, Hipodromo Condesa, Cuauhtemoc, 06100 Ciudad de Mexico",
  city: { name: "CDMX" },
  location: { latitude: 19.4044451, longitude: -99.1738529 },
  schedule:
    "Lunes a Viernes de 10:00 a 20:00 hrs\nSabados de 10:00 a 17:00 hrs",
  image: "sucursales/condesa-cdmx.jpg",
  place: "ChIJOxsYtGv_0YURuk7WiCqnzgo",
  maps: "https://g.page/TechHouseCondesa?share",
};

//This file contains the global constants for the application

export const COMPANY = {
  NAME: "TechHouse",
  EMAIL: "contacto@techhouse.com.mx",
  PHONE: "55 7040 6057",
  PHONE2: "55 7159 1321",
  FACEBOOK: "https://www.facebook.com/TechHouseMexico/",
  INSTAGRAM: "https://www.instagram.com/techhousemexico/",
  WHATSAPP:
    "https://api.whatsapp.com/send?phone=5215532515562&text=%C2%A1Hola%20Tech%20House!%20Necesito%20ayuda&fbclid=IwAR18b9Jvvl-PeM0a39u1VC1P9_tVd-SZVPoW6_MKdAhzU4UVlYB6KF8PNFk",
  TERMS_LINK: `https://techhouse.com.mx/piloto/documents/terminosycondiciones`,
  PRIVACY_LINK: "https://techhouse.com.mx/piloto/documents/avisodeprivacidad",
};

export const SUCURSALES: Sucursal[] = [
  {
    id: 1,
    name: "Narvarte",
    service: 0,
    address:
      "Eje Central Lázaro Cárdenas 911, Vértiz Narvarte, 03600 Ciudad de México, CDMX",
    schedule: "Lunes a Viernes de 10:00 a 19:00\nSábados de 10:00 a 17:00",
    phone: "55 7159 1321",
    location: { latitude: 19.382917, longitude: -99.147916 },
    image: null,
    maps: "https://g.page/TechHouseNarvarte?share",
    place_id: "ChIJL7ak4qr_0YURxjZ-mx2BDEo",
  },
  {
    id: 2,
    name: "Gran Patio Patria",
    service: 1,
    address:
      "Av. Patria Esq. Boulevard s/n, Royal Country, 45116 Zapopan, Jal.",
    schedule:
      "Lunes a Sabado de 10:00 hrs a 22:00 hrs\nDomingos de 11:00 hrs a 22:00 hrs",
    phone: "333 610 1739",
    location: { latitude: 20.7057062, longitude: -103.4173475 },
    image: "sucursales/gran-patio-patria-jal.jpg",
    maps: "https://g.page/TechHousePatioPatria?share",
    place_id: "ChIJEwiOwGWvKIQRU3l8Ptkuekw",
  },
  {
    id: 3,
    name: "Galerias Diana",
    service: 0,
    address: "Av Costera Miguel Alemán 1926, Magallanes, 39670 Acapulco, Gro.",
    schedule: "Todos los días de 10:00 a 21:00 hrs",
    phone: "744 688 6386",
    location: { latitude: 16.8604635, longitude: -99.8734545 },
    image: "sucursales/galerias-diana-gro.jpg",
    maps: "https://g.page/TechHouseGaleriasDiana?share",
    place_id: "ChIJs_7BMydYyoURsDEUmIjNIr0",
  },
  {
    id: 4,
    name: "Puerta La Victoria",
    service: 1,
    address:
      "Av. Constituyentes #40 Col. Villas del Sol, 76040, Querétaro, Qro.",
    schedule: "Todos los días de 9:00 a 22:00 hrs",
    phone: "442 325 0233",
    location: { latitude: 20.5851427, longitude: -100.3814314 },
    image: "sucursales/puerta-lavictoria-qro.jpg",
    maps: "https://g.page/TechHouseVictoriaQro?share",
    place_id: "ChIJkd8rkA9F04URqUKAIXVAc4A",
  },
  {
    id: 5,
    name: "Paseo Durango",
    service: 0,
    address: "Blvd. Felipe Pescador 1401, La Esperanza, 34080 Durango, Dgo.",
    schedule: "Todos los días de 12:00 a 20:00 hrs",
    phone: "618 129 27 69",
    location: { latitude: 24.0356475, longitude: -104.6540441 },
    image: "sucursales/paseo-durango-dur.jpg",
    maps: "https://goo.gl/maps/CLA3nujRwgeeLYhk7",
    place_id: "ChIJX0qt_6u3m4YRAdnZrpvClO0",
  },
  {
    id: 6,
    name: "Tablets Chilpancingo",
    service: 0,
    address:
      "Teófilo Olea y Leyva 10, Centro, 39000 Chilpancingo de los Bravo, Gro.",
    schedule: "Todos los días de 9:00 a 21:00 hrs",
    phone: "747 471 0296",
    location: { latitude: 17.5525296, longitude: -99.5056106 },
    image: "sucursales/tablets-chilpancingo-gro.jpg",
    maps: "https://g.page/TechHouseChilpancingo?share",
    place_id: "ChIJ-6aP11nty4URt9dGo1HasAE",
  },
  {
    id: 7,
    name: "Walmart Costera",
    service: 0,
    address:
      "Av Costera Miguel Alemán 500, Costa Azul, 39860 Acapulco de Juárez, Gro.",
    schedule: "Todos los días de 9:00 a 20:00 hrs",
    phone: "744 688 6386",
    location: { latitude: 16.8444842, longitude: -99.8475473 },
    image: null,
    maps: "https://g.page/TechHouseCostera?share",
    place_id: "ChIJM3kP1CxZyoUR3hnZRIftP8U",
  },
  {
    id: 8,
    name: "Galerias Valle Oriente",
    service: 1,
    address:
      "Av. Lázaro Cárdenas #1000 Col. Valle Oriente, 64750 Monterrey, N.L.",
    schedule: "Todos los días de 11:30 a 21:30 hrs",
    phone: "",
    location: { latitude: 25.6378869, longitude: -100.3145057 },
    image: "sucursales/galerias-valle-oriente-nl.jpg",
    maps: "https://g.page/TechHouseValleOriente?share",
    place_id: "ChIJpewxe9q_YoYRGj3adhA3B1w",
  },
  {
    id: 9,
    name: "Condesa",
    service: 1,
    address:
      "Av. Nuevo Leon 217A int. F, Hipodromo Condesa, Cuauhtemóc, 06100 Ciudad de México",
    schedule:
      "Lunes a Viernes de 10:00 a 20:00 hrs\nSábados de 10:00 a 17:00 hrs",
    phone: "",
    location: { latitude: 19.4044451, longitude: -99.1738529 },
    image: "sucursales/condesa-cdmx.jpg",
    maps: "https://g.page/TechHouseCondesa?share",
    place_id: "ChIJOxsYtGv_0YURuk7WiCqnzgo",
  },
  {
    id: 10,
    name: "Cumbres",
    service: 0,
    address:
      "Hacienda de Peñuelas 6773, Bosques de Las Cumbres, 64619 Monterrey, N.L.",
    schedule: "Todos los días de 9:00 a 21:00 hrs",
    phone: "",
    location: { latitude: 25.7329883, longitude: -100.3994212 },
    image: "sucursales/cumbres-nl.jpg",
    maps: "https://goo.gl/maps/ytUpVYn4bFQaZKCR6",
    place_id: "ChIJBzHfMECXYoYRyveZTBIuC3w",
  },
  {
    id: 11,
    name: "Encuentro Fortuna",
    service: 1,
    address:
      "Av Fortuna 334, Magdalena de las Salinas, 07760 Ciudad de México, CDMX",
    schedule: "Todos los días de 10:00 a 20:00 hrs",
    phone: "55 2121 8005",
    location: { latitude: 19.4820485, longitude: -99.1325417 },
    image: null,
    maps: "https://g.page/TechHouseFortuna?share",
    place_id: "ChIJD-IbyKf50YUR7-SwZUMq0Ak",
  },
  {
    id: 12,
    name: "Patio Santa Fe",
    service: 1,
    address:
      "Vasco de Quiroga 200-400, Santa Fe, Zedec Sta Fé, 01219 Ciudad de México, CDMX",
    schedule: "Todos los días de 12:00 a 20:00 hrs",
    phone: "55 5088 1866",
    location: { latitude: 19.3772299, longitude: -99.2567793 },
    image: "sucursales/patio-santafe-cdmx.jpg",
    maps: "https://g.page/TechHouseSantaFe?share",
    place_id: "ChIJayTSn-EA0oUR-1D7AYUkk38",
  },
  {
    id: 13,
    name: "Portal Centro",
    service: 1,
    address: "Lorenzo Boturini 258, Tránsito, 06820 Cuahtemoc, CDMX",
    schedule:
      "Lunes a miércoles de 12:00 a 22:00 hrs\nDe viernes a domingo de 12:00 a 22:00hrs",
    phone: "55 7040 6057",
    location: { latitude: 19.4186799, longitude: -99.1310541 },
    image: "sucursales/portal-centro-cdmx.jpg",
    maps: "https://g.page/DivyaPortalCentro?share",
    place_id: "ChIJua1qzsX-0YURdRHlSWQZJ6M",
  },
  {
    id: 14,
    name: "Del Valle",
    service: 1,
    address:
      "Parroquia 301-C, Del Valle, Benito Juárez, 03100, Ciudad de México",
    schedule:
      "Lunes a viernes de 10:00 a 20:00 hrs\nSábados de 10:00 hrs a 17:00 hrs",
    phone: "55 7090 5419",
    location: { latitude: 19.3705657, longitude: -99.1781425 },
    image: "sucursales/delvalle-cdmx.jpg",
    maps: "https://g.page/TechHouseDelValle?share",
    place_id: "ChIJZVDs9ZD_0YURATOv1hDs908",
  },
  {
    id: 15,
    name: "Interlomas",
    service: 0,
    address:
      "Av. de los Bosques 234, Lomas de tecamachalco, 52780, Naucalpan de Juárez, Edo. Mex.",
    schedule:
      "Lunes a viernes de 10:00 hrs a 18:00 hrs\nSábados de 9:00 hrs a 16:00 hrs",
    phone: "55 7040 6057",
    location: { latitude: 19.4064846, longitude: -99.2575485 },
    image: "sucursales/interlomas-cdmx.jpg",
    maps: "https://g.page/TechHouseInterlomasCDMX?share",
    place_id: "ChIJoU_o0kEB0oURmeYZ3QuW5HQ",
  },
  {
    id: 17,
    name: "Reforma 222",
    service: 1,
    address: "Av. Paseo de la Reforma 222, Cuauhtémoc, 06600, CDMX",
    schedule: "Todos los días de 12:00 a 20:00 hrs",
    phone: "55 7040 6057",
    location: { latitude: 19.4286725, longitude: -99.1616682 },
    image: null,
    maps: "https://g.page/TechHouseReforma222?share",
    place_id: "ChIJu6kEiyf_0YURrQsogFwaC60",
  },
];

export const NAVIGATION: { label: string; route?: string; href?: string }[] = [
  { label: "navigation.inicio", route: ROUTES.ROOT },
  {
    label: "navigation.cotizar",
    href: `${process.env.PUBLIC_URL}${ROUTES.QUOTE}`,
  },
  { label: "navigation.mireparacion", route: ROUTES.MYREPAIR },
  { label: "navigation.sucursales", route: ROUTES.SHOPS },
  { label: "navigation.tienda", href: "https://techhouse.com.mx/tienda" },
];

export const DINPUT_VALIDATIONS = {
  numberlike: /^[0-9,.\-\s]+$/,
  name: /^[a-zA-Z.,\-'\s]+$/,
  email: "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$", //eslint-disable-line no-useless-escape
  phone: /^[\d.\-()+\s]+$/,
  numbers: /^[\d]+$/,
  url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm, //eslint-disable-line no-useless-escape
};

export const MAX_LENGTHS = {
  EMAIL: 128,
};

/** Default Language */
export const DEFAULT_LANGUAGE = "es-MX";
